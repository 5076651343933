<i18n lang="yaml">
en:
  location: Location
  search: Search
  apply: Apply
  clearAll: Clear all

fr:
  location: Endroit
  search: Rechercher
  apply: Appliquer
  clearAll: Tout effacer
</i18n>

<template>
  <Drawer :show="showDrawer && showForm" slide="slide-top" @close="close()">
    <template #content>
      <SearchMobileInput
        :type-location="typeLocation"
        @changed="locationChanged"
        @click="typeLocation = !typeLocation"
      />
    </template>
  </Drawer>
  <div v-if="!showDrawer" v-bind="$attrs">
    <SearchMobileInput
      :type-location="typeLocation"
      @changed="typeLocation = false"
      @click="typeLocation = !typeLocation"
    />
    <CommonInputButton
      class="w-full"
      variant="highlight"
      size="sm"
      v-if="buttonText"
      :disabled="disableButtonState"
      @click="searchClicked"
    >
      {{ buttonText }}
    </CommonInputButton>
  </div>
</template>

<script lang="ts" setup>
import { AppProviderKeys } from '~/providers'
import type { IPSearchParameters } from '~/types/provider/search-parameters'

const { navigate, disableButtonState, enableSearchButton } = useSearchNavigateTo()

withDefaults(
  defineProps<{
    showForm?: boolean
    showDrawer?: boolean
    buttonText?: string
  }>(),
  {
    showDrawer: true
  }
)

const typeLocation = ref(false)
const sp = inject<IPSearchParameters>(AppProviderKeys.SearchParameters) as IPSearchParameters

const close = () => {
  typeLocation.value = false
  emit('close')
}

onMounted(() => {
  enableSearchButton()
})

const locationChanged = async () => {
  typeLocation.value = false
  await searchClicked()
  close()
}

const emit = defineEmits<{
  close: any
}>()

const searchClicked = async () => {
  await navigate(sp, getRouteBaseName())
}
</script>
