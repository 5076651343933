<i18n lang="yaml">
en:
  location: Location

fr:
  location: Endroit
</i18n>

<template>
  <div class="parameters-grid">
    <div class="location">
      <LazySearchLocationField v-show="typeLocation" @changed="emit('changed')" />
      <div v-show="!typeLocation">
        <div class="typography-caption text-primary-350">{{ t('location') }}</div>
        <button type="button" class="parameter-btn" @click="emit('click')">
          <SearchLocationText />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  typeLocation?: boolean
}>()
const emit = defineEmits<{
  changed: [value: void]
  click: [value: void]
}>()
const { t } = useI18n({ useScope: 'local' })
</script>

<style scoped lang="scss">
.parameters-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'location location';
  grid-row-gap: 1rem;
  margin-bottom: 1rem;
}

.location {
  grid-area: location;
  padding-bottom: 1rem;
  border-bottom: 1px solid $primary-100;
  position: relative;
}

.parameter-btn {
  background: none;
  border: none;
  text-align: left;
  font-size: 1rem;
  color: $primary-500;
  display: block;
  width: 100%;
}
</style>
